/* eslint-disable */
import axios from 'axios';
import { Notification, MessageBox, Message, } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import errorCode from '@/utils/errorCode';
import { oaBaseUrl } from './constant';
import { tansParams } from '@/utils/index';

export const isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers["Content-Security-Policy"]="upgrade-insecure-requests";
const service = axios.create({
  baseURL: oaBaseUrl,
  timeout: 10000,
});

service.interceptors.request.use((config) => {
  const isToken = (config.headers || {}).isToken === false;
  if (getToken() && !isToken) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  if (config.method === 'get' && config.params) {
    let url = `${config.url}?${tansParams(config.params)}`;
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config;
}, (error) => {
  console.log(error);
  Promise.reject(error);
});

const ignoreErrUrl = [
  '/hrm/info/',
];

service.interceptors.response.use((res) => {
  const code = res.data.code || 200;
  const msg = errorCode[code] || res.data.msg || errorCode.default;
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data;
  }
  if (code === 401) {
    if (!isRelogin.show) {
      isRelogin.show = true;
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        isRelogin.show = false;
        store.dispatch('user/logout').then(() => {
          location.href = '/login';
        });
      }).catch(() => {
        isRelogin.show = false;
      });
    }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
  } else if (code === 500) {
    for(let i = 0; i < ignoreErrUrl.length; i += 1) {
      if (res.config.url.indexOf(ignoreErrUrl[i]) !== -1) {
        return Promise.reject(new Error(msg));
      }
    }
    Message({
      message: msg,
      type: 'error',
    });
    return Promise.reject(new Error(msg));
  } else if (code !== 200) {
    Notification.error({
      title: msg,
    });
    return Promise.reject('error');
  }
  return res.data;
},
(error) => {
  let { message } = error;
  if (message == 'Network Error') {
    message = '后端接口连接异常';
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时';
  } else if (message.includes('Request failed with status code')) {
    message = `系统接口${message.substr(message.length - 3)}异常`;
  }
  Message({
    message,
    type: 'error',
    duration: 5 * 1000,
  });
  return Promise.reject(error);
});


export default service;
