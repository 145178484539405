<template>
  <div class="content">
    <!-- banner -->
    <div class="join_banner">
      <img class="join_img" src="./img/pc.jpg" v-if="this.$store.state.pageset.pcshow" />
      <img class="join_img" src="./img/mobile.jpg" width="100%" v-else />
    </div>
    <!-- 中部内容 -->
    <div class="join_content">
      <div class="join_choose">
        <p>搜索</p>
        <el-input
          v-model="parmas.searchValue"
          clearable
          placeholder="可输入岗位名称、地点搜索"
          @keyup.enter.native="getDemand"
          @clear="getDemand"
        ></el-input>
        <p>职位类型</p>
        <el-select v-model="parmas.type" placeholder="请选择" style="width: 100%">
          <el-option label="全职" value="全职"> </el-option>
        </el-select>
        <p>地区</p>
        <el-select v-model="parmas.area" placeholder="请选择" style="width: 100%">
          <el-option label="中国" value="中国"> </el-option>
        </el-select>
        <!-- <p>地点</p>
        <el-select
          v-model="parmas.searchValue"
          placeholder="请选择"
          @change="getDemand"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in place"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select> -->
        <el-button
          size="small"
          class="btn-div-experience black-btn"
          plain
          @click="clearPar"
          >重置筛选条件</el-button
        >
      </div>

      <!-- 查询内容 -->
      <div class="join_content_value" v-if="this.$store.state.pageset.pcshow">
        <el-table
          v-if="tableData.length"
          :data="tableData"
          v-loading="tableLoading"
          style="width: 900px"
          height="672px"
          :header-cell-style="{
            backgroundColor: '#f4f4f4',
            height: '56px',
          }"
        >
          <el-table-column prop="postName" label="岗位">
            <template slot-scope="scope">
              <span style="text-decoration: underline">{{ scope.row.postName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="地区" width="100">
            <template> 中国 </template>
          </el-table-column>
          <el-table-column prop="basePlace" label="地址"> </el-table-column>
        </el-table>
        <p v-else>未找到结果</p>
      </div>
      <div class="join_content_value" v-else>
        <div v-if="tableData.length">
          <div
            v-animate="'queue-bottom'"
            v-for="(item, index) in tableData"
            :key="index"
            class="join_content_value_post"
          >
            <p class="p1">{{ item.postName }}</p>
            <p class="p2">{{ item.basePlace }}</p>
          </div>
        </div>
        <p v-else>未找到结果</p>
      </div>
    </div>
  </div>
</template>
<script>
import joinus from "./joinus.js";

export default joinus;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "joinus.scss";
</style>
