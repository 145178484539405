
import { getDemand } from '@/api/joinUs/joinUs.js';
export default {
    name: 'Joinus',
    components: {

    },
    data() {
        return {
            parmas: {
                deptList: [],
                recruitList: ["1"],
                searchValue: '',
                delFlag: 0,
                postName: '',
                type: '全职',
                area: '中国'
            }
            ,
            join_choose: [
                {
                    name: '所有类别',
                },
                {
                    name: '市场营销类',
                },
                {
                    name: '技术研发类',
                },
                {
                    name: '生产交付类',
                },
            ],//选择条件
            place: [
                {
                    name: '重庆',
                },
                {
                    name: '北京',
                },
                {
                    name: '上海',
                }
                ,
                {
                    name: '全国',
                }
                ,
                {
                    name: '陕西',
                }
                ,
                {
                    name: '南京',
                }
                ,
                {
                    name: '西宁',
                }
            ],
            tableData: [],
            tableLoading: false
        }
    },

    computed: {

    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        this.getDemand();
    },
    methods: {
        // 获取招聘岗位
        async getDemand() {
            this.tableLoading = true;
            let res = await getDemand(this.parmas);
            if (res.code === 200) {
                this.tableData = res.rows;
                this.tableLoading = false;
            } else {
                this.tableLoading = false;
            }
        },
        clearPar() {
            this.parmas.basePlace = '';
            this.parmas.postName = '';
            this.getDemand();
        }
    }
}